export default {
  '0x224d8fd7ab6ad4c6eb4611ce56ef35dec2277f03': {
    address: '0x224d8fd7ab6ad4c6eb4611ce56ef35dec2277f03',
    chainId: 59144,
    name: 'ETH',
    symbol: 'ETH',
    icon: 'https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628',
    decimals: 18,
    poolId: 13,
    poolAddress: '0xAad094F6A75A14417d39f04E690fC216f080A41a',
    coinGeckoId: 'ethereum',
  },
};
