import { Contract } from '@ethersproject/contracts';
import BigNumber from 'bignumber.js';
import { useAccount } from 'wagmi';
import { Web3Provider } from '@ethersproject/providers';

export default function useBaseBridge() {
  const { address: account } = useAccount();

  const toETH = async ({
    amount,
    chain,
    targetChain,
    token,
    onSuccess,
    isQuote,
  }) => {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner(account);
    const RouterContract = new Contract(
      '0x4200000000000000000000000000000000000016',
      [
        {
          inputs: [
            { internalType: 'address', name: '_target', type: 'address' },
            { internalType: 'uint256', name: '_gasLimit', type: 'uint256' },
            { internalType: 'bytes', name: '_data', type: 'bytes' },
          ],
          name: 'initiateWithdrawal',
          outputs: [],
          stateMutability: 'payable',
          type: 'function',
        },
      ],
      signer
    );
    const _amount = new BigNumber(amount).multipliedBy(10 ** 18).toString();
    if (isQuote) {
      const response = await RouterContract.estimateGas.initiateWithdrawal(
        account,
        '100000',
        1,
        {
          value: _amount,
        }
      );
      return new BigNumber(response._hex).div(10 ** 18);
    }
    const tx = await RouterContract.initiateWithdrawal(account, '100000', 1, {
      value: _amount,
    });
    const res = await tx.wait();
    if (res.status === 1) {
      onSuccess();
      const bridgeTxs = localStorage.getItem('bridgeTxs') || '{}';
      const _bridgeTxs = JSON.parse(bridgeTxs);
      _bridgeTxs[tx.hash] = {
        amount,
        label: `${chain.name} -> ${targetChain.name} Chain`,
        symbol: token.symbol,
        tx: tx.hash,
        isBaseBridge: true,
      };
      localStorage.setItem('bridgeTxs', JSON.stringify(_bridgeTxs));
    }
  };
  const despoitToETH = async ({
    amount,
    chain,
    targetChain,
    token,
    onSuccess,
    isQuote,
  }) => {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner(account);
    const RouterContract = new Contract(
      '0x49048044D57e1C92A77f79988d21Fa8fAF74E97e',
      [
        {
          inputs: [
            { internalType: 'address', name: '_to', type: 'address' },
            { internalType: 'uint256', name: '_value', type: 'uint256' },
            { internalType: 'uint64', name: '_gasLimit', type: 'uint64' },
            { internalType: 'bool', name: '_isCreation', type: 'bool' },
            { internalType: 'bytes', name: '_data', type: 'bytes' },
          ],
          name: 'depositTransaction',
          outputs: [],
          stateMutability: 'payable',
          type: 'function',
        },
      ],
      signer
    );
    const _amount = new BigNumber(amount).multipliedBy(10 ** 18).toString();
    if (isQuote) {
      const response = await RouterContract.estimateGas.depositTransaction(
        account,
        _amount,
        '100000',
        false,
        '0x01',
        {
          value: _amount,
        }
      );
      return new BigNumber(response._hex).div(10 ** 18);
    }
    const tx = await RouterContract.depositTransaction(
      account,
      _amount,
      '100000',
      false,
      '0x01',
      {
        value: _amount,
      }
    );
    const res = await tx.wait();
    if (res.status === 1) {
      onSuccess();
      const bridgeTxs = localStorage.getItem('bridgeTxs') || '{}';
      const _bridgeTxs = JSON.parse(bridgeTxs);
      _bridgeTxs[tx.hash] = {
        amount,
        label: `${chain.name} -> ${targetChain.name} Chain`,
        symbol: token.symbol,
        tx: tx.hash,
        isBaseBridge: true,
      };
      localStorage.setItem('bridgeTxs', JSON.stringify(_bridgeTxs));
    }
  };
  const toERC20 = async ({
    amount,
    chain,
    targetChain,
    token,
    onSuccess,
    isQuote,
  }) => {
    if (!amount) return;
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner(account);
    const RouterContract = new Contract(
      '0x4200000000000000000000000000000000000010',
      [
        {
          inputs: [
            { internalType: 'address', name: '_l2Token', type: 'address' },
            { internalType: 'uint256', name: '_amount', type: 'uint256' },
            { internalType: 'uint32', name: '_minGasLimit', type: 'uint32' },
            { internalType: 'bytes', name: '_extraData', type: 'bytes' },
          ],
          name: 'withdraw',
          outputs: [],
          stateMutability: 'payable',
          type: 'function',
        },
      ],
      signer
    );
    const _amount = new BigNumber(amount)
      .multipliedBy(10 ** token.decimals)
      .toString();
    if (isQuote) {
      const response = await RouterContract.estimateGas.withdraw(
        token.address,
        _amount,
        '100000',
        '0x01'
      );
      return new BigNumber(response._hex).div(10 ** 18);
    }
    const tx = await RouterContract.withdraw(
      token.address,
      _amount,
      '100000',
      '0x01',
      { gasLimit: '10000000' }
    );
    const res = await tx.wait();
    if (res.status === 1) {
      onSuccess();
      const bridgeTxs = localStorage.getItem('bridgeTxs') || '{}';
      const _bridgeTxs = JSON.parse(bridgeTxs);
      _bridgeTxs[tx.hash] = {
        amount,
        label: `${chain.name} -> ${targetChain.name} Chain`,
        symbol: token.symbol,
        tx: tx.hash,
        isBaseBridge: true,
      };
      localStorage.setItem('bridgeTxs', JSON.stringify(_bridgeTxs));
    }
  };
  const despoitToERC20 = async ({
    amount,
    token,
    chain,
    targetChain,
    targetToken,
    isQuote,
    onSuccess,
  }) => {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner(account);
    const RouterContract = new Contract(
      '0x3f3c0f6bc115e698e35038e1759e9c31032e590c',
      [
        {
          inputs: [
            { internalType: 'address', name: '_l1Token', type: 'address' },
            { internalType: 'address', name: '_l2Token', type: 'address' },
            { internalType: 'uint256', name: '_amount', type: 'uint256' },
            { internalType: 'uint32', name: '_minGasLimit', type: 'uint32' },
            { internalType: 'bytes', name: '_extraData', type: 'bytes' },
          ],
          name: 'depositERC20',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      signer
    );
    const _amount = new BigNumber(amount)
      .multipliedBy(10 ** token.decimals)
      .toString();
    if (isQuote) {
      const response = await RouterContract.estimateGas.depositERC20(
        token.address,
        targetToken.address,
        _amount,
        '100000',
        '0x01',
        { gasLimit: '10000000' }
      );
      return new BigNumber(response._hex).div(10 ** 18);
    }
    const tx = await RouterContract.depositERC20(
      token.address,
      targetToken.address,
      _amount,
      '100000',
      '0x01',
      { gasLimit: '10000000' }
    );
    const res = await tx.wait();
    if (res.status === 1) {
      onSuccess();
      const bridgeTxs = localStorage.getItem('bridgeTxs') || '{}';
      const _bridgeTxs = JSON.parse(bridgeTxs);
      _bridgeTxs[tx.hash] = {
        amount,
        label: `${chain.name} -> ${targetChain.name} Chain`,
        symbol: token.symbol,
        tx: tx.hash,
        isBaseBridge: true,
      };
      localStorage.setItem('bridgeTxs', JSON.stringify(_bridgeTxs));
    }
  };
  const quote = async ({ token, targetToken, amount, chain }) => {
    let gasCost = new BigNumber(0);
    if (targetToken.isNative) {
      gasCost =
        chain.id === 1
          ? await despoitToETH({ amount, isQuote: true })
          : await toETH({ amount, isQuote: true });
    } else {
      gasCost =
        chain.id === 1
          ? await despoitToERC20({ amount, token, targetToken, isQuote: true })
          : await toERC20({ amount, token, isQuote: true });
    }
    return gasCost;
  };
  const swap = async ({
    token,
    chain,
    targetChain,
    targetToken,
    amount,
    onSuccess,
  }) => {
    if (targetToken.isNative) {
      chain.id === 1
        ? await despoitToETH({
            token,
            targetToken,
            chain,
            targetChain,
            amount,
            onSuccess,
            isQuote: false,
          })
        : await toETH({
            token,
            targetToken,
            chain,
            targetChain,
            amount,
            onSuccess,
            isQuote: false,
          });
    } else {
      chain.id === 1
        ? await despoitToERC20({
            token,
            targetToken,
            chain,
            targetChain,
            amount,
            onSuccess,
            isQuote: false,
          })
        : await toERC20({
            token,
            targetToken,
            chain,
            targetChain,
            amount,
            onSuccess,
            isQuote: false,
          });
    }
  };

  return {
    swap,
    quote,
  };
}
