import { LoadingOutlined } from '@ant-design/icons';
import useChains from '../hooks/useChains';
import useTokenBalance from '../hooks/useTokenBalance';

export default function TokenAmount({ token }) {
  const chains = useChains();
  const { balance, loading } = useTokenBalance(token, chains[token.chainId]);
  return loading ? (
    <LoadingOutlined />
  ) : (
    <div className='token-amount'>{balance ? balance.toFixed(2) : '0.00'}</div>
  );
}
