import { useEffect, useState } from 'react';
import { useConnect } from 'wagmi';
import { MetaMaskConnector } from '@wagmi/connectors/metaMask';
import useChains from './useChains';
import { setupNetwork } from '../utils/wallet';

export default function useCurrentChain() {
  const chains = useChains();
  const [currentChain, setCurrentChain] = useState({});
  const [requesting, setRequesting] = useState(false);
  const { connect } = useConnect({
    connector: new MetaMaskConnector(),
  });

  const setChain = async (chain) => {
    setRequesting(true);
    const flag = await setupNetwork(chain);
    setRequesting(false);
    if (flag) {
      setCurrentChain(chain);
      connect();
    }
  };
  useEffect(() => {
    const getChainId = async () => {
      const provider = window.ethereum;
      const result = await provider.request({
        method: 'eth_chainId',
      });
      const chainId = Number(result);
      setCurrentChain(chains[chainId]);
    };
    if (!currentChain.id) getChainId();
    window?.ethereum.on('chainChanged', (chainId) => {
      const chain = chains[Number(chainId)];
      if (chain) setCurrentChain(chain);
    });
  }, []);
  return { requesting, currentChain, setCurrentChain: setChain };
}
