import { useState, useEffect } from 'react';
import { Input, Modal, Flex } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import useChains from '../hooks/useChains';
import TokenLogo from './TokenLogo';
import TokenAmount from './TokenAmount';

export default function TokensModal({
  list: tokens,
  isOpen,
  setIsOpen,
  modifyToken,
}) {
  const [list, setList] = useState([]);
  const chains = useChains();
  const handleChange = (ev) => {
    if (!ev.target.value) return;
    const data = tokens.filter(
      (token) =>
        token.name.includes(ev.target.value) ||
        token.address.toLowerCase() === ev.target.value.toLowerCase()
    );
    setList(data);
  };
  useEffect(() => {
    setList(tokens);
  }, [tokens]);

  return (
    <Modal
      open={isOpen}
      footer={null}
      onCancel={() => {
        setIsOpen(false);
      }}
      title='SELECT TOKEN'
      width={500}
    >
      <div className='modalContent'>
        <Input
          className='modalInput'
          placeholder='Search Token'
          onChange={handleChange}
          suffix={<SearchOutlined />}
        />
        <div className='token-list'>
          {list.map((token) => {
            return (
              <div
                className='tokenChoice'
                key={token.address}
                onClick={() => modifyToken(token)}
              >
                <Flex gap={16}>
                  <TokenLogo token={token} />
                  <div className='tokenChoiceNames'>
                    <div className='tokenName'>{token.symbol}</div>
                    <div className='tokenTicker'>
                      {chains[token.chainId].name}
                    </div>
                  </div>
                </Flex>
                <div className='token-amount'>
                  <TokenAmount token={token} />
                </div>
              </div>
            );
          })}
        </div>
        {list.length === 0 && (
          <div style={{ textAlign: 'center', color: 'rgb(100 116 139)' }}>
            No Tokens.
          </div>
        )}
      </div>
    </Modal>
  );
}
