import { Button, Input, InputNumber, message, Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import { SwapOutlined } from '@ant-design/icons';
import { JsonRpcProvider } from '@ethersproject/providers';
import BigNumber from 'bignumber.js';
import { useAccount, useConnect } from 'wagmi';
import { MetaMaskConnector } from '@wagmi/connectors/metaMask';
import { isAddress } from '../utils/address';
import TokensModal from './TokensModal';
import ChainsModal from './ChainsModal';
import Currency from './Currency';
import Result from './Result';
import useTokenBalance from '../hooks/useTokenBalance';
import useCurrentChain from '../hooks/useCurrentChain';
import useChains from '../hooks/useChains';
import useTokens from '../hooks/useTokens';
import useApprove from '../hooks/useApprove';
import useBestRoute from '../hooks/useBestRoute';

function Swap() {
  const [messageApi, contextHolder] = message.useMessage();
  const { currentChain, setCurrentChain, requesting } = useCurrentChain();
  const { connect } = useConnect({
    connector: new MetaMaskConnector(),
  });
  const { tokens } = useTokens();
  const chains = useChains();
  const { address: account } = useAccount();
  const [amount, setAmount] = useState();
  const [tokenOne, setTokenOne] = useState(null);
  const [tokenTwo, setTokenTwo] = useState(null);
  const [chainOne, setChainOne] = useState(null);
  const [chainTwo, setChainTwo] = useState(null);
  const [tokenList, setTokenList] = useState([]);
  const [chainList, setChainList] = useState([]);
  const [type, setType] = useState(1);
  const [isTokenOpen, setIsTokenOpen] = useState(false);
  const [isChainOpen, setIsChainOpen] = useState(false);
  const [showDestination, setShowDestination] = useState(false);
  const [destination, setDestination] = useState(null);
  const [error, setError] = useState();
  const [update, setUpdate] = useState();
  const [dexs, setDexs] = useState([]);
  const [currentDex, setCurrentDex] = useState(null);
  const { balance, loading: balanceLoading } = useTokenBalance(
    tokenOne,
    chainOne,
    update
  );
  const { approved, approve, approving } = useApprove({
    token: tokenOne,
    amount,
    chain: chainOne,
    currentDex,
  });

  const { getBestRoute, swap, swaping, checking } = useBestRoute();

  const onAmountChange = (value) => {
    setAmount(value);
    setError(balance && balance.lt(value) ? 'Insufficient balance' : null);
  };

  const getBestTrade = async () => {
    const _dexs = await getBestRoute({
      targetToken: tokenTwo,
      chain: chainOne,
      token: tokenOne,
      targetChain: chainTwo,
      amount,
    });
    setDexs(_dexs);
    setCurrentDex(_dexs.length ? _dexs[0] : null);
  };
  const modifyToken = (token) => {
    const _tokens = type === 1 ? [token, tokenTwo] : [tokenOne, token];
    let _targetChain = type === 1 ? chainTwo : chainOne;
    if (token.chainId !== _targetChain?.id) {
      (type === 1 ? setChainOne : setChainTwo)(chains[token.chainId]);
    }
    _tokens[1] = Object.values(tokens).find(
      (_token) => _token.poolId === token.poolId
    );
    if (!chainTwo || !_tokens[1]) {
      const chainList = Object.values(tokens)
        .filter((_token) => {
          if (_token.chainId === token.chainId) return false;
          return _token.poolId === token.poolId;
        })
        .map((token) => chains[token.chainId]);
      _targetChain = chainList[0];
      setChainTwo(chainList[0]);
    }
    setIsTokenOpen(false);
    setTokenOne(_tokens[0]);
    setTokenTwo(_tokens[1]);
  };
  const modifyChain = (chain) => {
    (type === 1 ? setChainOne : setChainTwo)(chain);
    const currentToken = type === 1 ? tokenOne : tokenTwo;
    const chains = type === 1 ? [chain, chainTwo] : [chainOne, chain];
    let _tokenTwo = tokenTwo;
    if (currentToken && chain.id !== currentToken?.chainId) {
      const _token = Object.values(tokens).find(
        (token) =>
          token.poolId === currentToken.poolId && token.chainId === chain.id
      );
      (type === 1 ? setTokenOne : setTokenTwo)(_token);
      type === 2 && (_tokenTwo = _token);
    }
    setIsChainOpen(false);
    setError(chains[0].id === chains[1].id ? 'Select different network' : '');
    if (!tokenOne && !tokenTwo && chains[0] && chains[1]) {
      let _temp = {};
      const _tokens = Object.values(tokens);
      for (let i = 0; i < _tokens.length; i++) {
        const _token = _tokens[i];
        if (
          _temp[_token.poolId] &&
          (_token.chainId === chains[1].id || _token.chainId === chains[0].id)
        ) {
          _tokenTwo =
            _token.chainId === chains[1].id ? _token : _temp[_token.poolId];
          setTokenOne(
            _token.chainId === chains[0].id ? _token : _temp[_token.poolId]
          );
          setTokenTwo(_tokenTwo);
        }
        if (
          _token.chainId === chains[0].id ||
          _token.chainId === chains[1].id
        ) {
          _temp[_token.poolId] = _token;
        }
      }
    }
  };
  const handleTokenOpen = (type) => {
    if (type === 1) {
      const _tokens = Object.values(tokens);
      setTokenList(
        false
          ? _tokens.sort((a, b) => {
              if (a.chainId === chainOne.id) return -1;
              return 1;
            })
          : _tokens
      );
    }
    setIsTokenOpen(true);
    setType(type);
  };
  const handleChainOpen = (type) => {
    setIsChainOpen(true);
    setType(type);
    if (type === 1) {
      setChainList(Object.values(chains));
      return;
    }
    if (type === 2 && tokenOne) {
      const chainList = Object.values(tokens)
        .filter((token) => {
          if (token.chainId === tokenOne.chainId) return false;
          return token.symbol === tokenOne.symbol;
        })
        .map((token) => chains[token.chainId]);
      setChainList(chainList);
      return;
    }
    setChainList(Object.values(chains));
  };
  const handleClick = () => {
    if (getButtonText() === 'Connect to Wallet') {
      connect();
      return;
    }
    if (getButtonText() === 'Approve') {
      approve();
      return;
    }
    if (getButtonText() === `Switch to ${chainOne.name}`) {
      setCurrentChain(chainOne);
      return;
    }
    swap({
      chain: chainOne,
      token: tokenOne,
      targetChain: chainTwo,
      targetToken: tokenTwo,
      amount,
      destination,
      dexName: currentDex?.name,
      onSuccess: (tx) => {
        messageApi.success(
          `Bridge ${amount} ${tokenOne.symbol} from ${chainOne.name} to ${chainTwo.name}`
        );
        setUpdate(Date.now());
      },
    });
  };
  const checkGasIsEnough = async () => {
    const provider = new JsonRpcProvider(chainOne.rpcUrls.default);
    const res = await provider.getBalance(account);
    const _balance = new BigNumber(res._hex).div(
      10 ** chainOne.nativeCurrency.decimals
    );
    if (_balance.lt(currentDex.gasCost)) {
      setError(
        `Not enough gas(${new BigNumber(currentDex.gasCost).toFixed(3)} ${
          chainOne.nativeCurrency.symbol
        }) needed`
      );
    }
  };
  const getButtonText = () => {
    if (checking) return 'Checking';
    if (approving) return 'Approving';
    if (swaping) return 'Swaping';
    if (error) return error;
    if (!account) return 'Connect to Wallet';
    if (!amount) return 'Enter amount';
    if (!approved) return 'Approve';
    if (chainOne.id !== currentChain.id) return `Switch to ${chainOne.name}`;
    return 'Transfer';
  };

  useEffect(() => {
    if (!chainOne?.id) setChainOne(currentChain);
  }, [currentChain]);

  useEffect(() => {
    if (currentDex && account && chainOne?.id && !error) checkGasIsEnough();
  }, [currentDex, account, chainOne, error]);

  useEffect(() => {
    if (!tokenOne && !tokenTwo && chainOne?.id && !chainTwo) {
      const _tokens = Object.values(tokens);
      const _chainOneTokens = _tokens.filter(
        (token) => token.chainId === chainOne.id
      );
      const _token1 = _chainOneTokens[0];
      for (let i = 0; i < _tokens.length; i++) {
        if (_tokens[i].poolId === _token1.poolId) {
          setTokenOne(_token1);
          setTokenTwo(_tokens[i]);
          setChainTwo(chains[_tokens[i].chainId]);
          break;
        }
      }
    }
  }, [tokens, chainOne]);

  useEffect(() => {
    if (account) getBestTrade();
  }, [tokenOne, tokenTwo, amount, chainOne, chainTwo, currentChain, account]);

  return (
    <>
      {contextHolder}
      <TokensModal
        isOpen={isTokenOpen}
        setIsOpen={setIsTokenOpen}
        modifyToken={modifyToken}
        list={tokenList}
      />
      <ChainsModal
        isOpen={isChainOpen}
        setIsOpen={setIsChainOpen}
        modifyChain={modifyChain}
        list={chainList}
      />
      <div className='tradeBox'>
        <div className='panel-shadow'></div>
        <div className='panel-content'>
          <div className='tradeBoxHeader'>
            <h4>TRANSFER</h4>
          </div>
          <div className='bridge-label'>From</div>
          <Currency
            token={tokenOne}
            chain={chainOne}
            onTokenClick={() => {
              handleTokenOpen(1);
            }}
            onChainToken={() => {
              handleChainOpen(1);
            }}
          />
          <div
            className='swap-icon'
            onClick={() => {
              const [_tokenOne, _tokenTwo] = [tokenTwo, tokenOne];
              const [_chainOne, _chainTwo] = [chainTwo, chainOne];
              setTokenOne(_tokenOne);
              setTokenTwo(_tokenTwo);
              setChainOne(_chainOne);
              setChainTwo(_chainTwo);
            }}
          >
            <SwapOutlined />
          </div>
          <div className='bridge-label'>To</div>
          <Currency
            token={tokenTwo}
            chain={chainTwo}
            disabled={true}
            onChainToken={() => {
              handleChainOpen(2);
            }}
          />
          <div className='balance-wrap'>
            <div className='bridge-label'>Total Amount</div>
            <div className='balance'>
              Balance:{' '}
              {!balanceLoading
                ? balance
                  ? balance?.toFixed(6)
                  : '-'
                : 'Loading...'}
            </div>
          </div>
          <div className='input-wrapper'>
            <InputNumber
              className='input'
              stringMode
              controls={false}
              onChange={onAmountChange}
              value={amount}
              placeholder='0.0'
            />
            <Button
              size='small'
              className='max-button'
              onClick={() => {
                setAmount(balance);
              }}
              disabled={balanceLoading || !balance}
            >
              Max
            </Button>
          </div>
          <div className='destination'>
            <Checkbox
              checked={showDestination}
              disabled={currentDex?.name !== 'Stargate'}
              onChange={(ev) => {
                setShowDestination(ev.target.checked);
                if (
                  !ev.target.checked &&
                  error === 'Change destination address'
                ) {
                  setError('');
                }
              }}
            >
              I'm transferring to another address
            </Checkbox>
            {showDestination && (
              <div className='input-wrapper'>
                <Input
                  className='input'
                  stringMode
                  controls={false}
                  value={destination}
                  placeholder='Destination Address'
                  disabled
                />
                <Button
                  size='small'
                  className='max-button'
                  onClick={async () => {
                    const text = await navigator.clipboard.readText();

                    if (!text) return;
                    if (isAddress(text)) {
                      setDestination(isAddress(text));
                      setError(null);
                    } else {
                      setError('Change destination address');
                    }
                  }}
                >
                  Paste
                </Button>
              </div>
            )}
          </div>
          {currentDex && (
            <Result
              dexs={dexs}
              currentDex={currentDex}
              amount={amount}
              token={tokenOne}
              chain={chainOne}
              onChangeDex={(_dex) => {
                setCurrentDex(_dex);
                if (_dex.name === 'Stargate') {
                  setShowDestination(false);
                  setDestination('');
                }
              }}
            />
          )}
          <Button
            className='transfer-button'
            block
            type='primary'
            size='large'
            disabled={
              currentChain?.id !== chainOne?.id || !account
                ? false
                : !!error || !amount || !currentDex
            }
            loading={checking || approving || swaping || requesting}
            onClick={handleClick}
          >
            {getButtonText()}
          </Button>
        </div>
      </div>
    </>
  );
}

export default Swap;
