import React from 'react';
import { Flex, Dropdown } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import useChains from '../hooks/useChains';
import useCurrentChain from '../hooks/useCurrentChain';

function Label({ chain, loading, ...rest }) {
  return (
    <Flex align='center' {...rest}>
      {!loading ? (
        <img src={chain.icon} alt={chain.name} className='chainLogo' />
      ) : (
        <LoadingOutlined />
      )}
      {!loading ? chain.name : 'Requesting'}
    </Flex>
  );
}

function Header(props) {
  const { connect, isConnected, address } = props;
  const chains = useChains();
  const { currentChain, setCurrentChain, requesting } = useCurrentChain();
  return (
    <Flex justify='space-between' align='center'>
      <img src='/logo192.png' />
      <div className='rightH'>
        <Dropdown
          menu={{
            items: Object.values(chains).map((chain) => ({
              key: chain.id,
              label: (
                <Label
                  chain={chain}
                  className='chainItem'
                  onClick={() => {
                    setCurrentChain(chain);
                  }}
                />
              ),
            })),
          }}
        >
          {currentChain ? (
            <Label
              className='chain-selector'
              chain={currentChain}
              loading={requesting}
            />
          ) : (
            <div className='chain-selector'>Network</div>
          )}
        </Dropdown>

        <div className='connect-button' onClick={connect}>
          {isConnected
            ? address.slice(0, 4) + '...' + address.slice(38)
            : 'Connect'}
        </div>
      </div>
    </Flex>
  );
}

export default Header;
