export default {
  '0x72E2F4830b9E45d52F80aC08CB2bEC0FeF72eD9c': {
    address: '0x72E2F4830b9E45d52F80aC08CB2bEC0FeF72eD9c',
    chainId: 1,
    name: 'ETH',
    symbol: 'ETH',
    icon: 'https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628',
    decimals: 18,
    poolId: 13,
    poolAddress: '0x101816545F6bd2b1076434B54383a1E633390A2E',
    coinGeckoId: 'ethereum',
  },
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48': {
    address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    chainId: 1,
    name: 'USDC',
    symbol: 'USDC',
    icon: 'https://assets.coingecko.com/coins/images/6319/standard/usdc.png?1696506694',
    decimals: 6,
    poolId: 1,
    poolAddress: '0xdf0770dF86a8034b3EFEf0A1Bb3c889B8332FF56',
    coinGeckoId: 'usd-coin',
  },
  '0xdAC17F958D2ee523a2206206994597C13D831ec7': {
    address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    chainId: 1,
    name: 'Tether USD',
    symbol: 'USDT',
    icon: 'https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661',
    decimals: 6,
    poolId: 2,
    poolAddress: '0x38ea452219524bb87e18de1c24d3bb59510bd783',
    coinGeckoId: 'tether',
  },
  '0x0C10bF8FcB7Bf5412187A595ab97a3609160b5c6': {
    address: '0x0C10bF8FcB7Bf5412187A595ab97a3609160b5c6',
    chainId: 1,
    name: 'Decentralized USD',
    symbol: 'USDD',
    icon: 'https://assets.coingecko.com/coins/images/25380/standard/UUSD.jpg?1696524513',
    decimals: 18,
    poolId: 11,
    poolAddress: '0x692953e758c3669290cb1677180c64183cEe374e',
    coinGeckoId: 'usdd',
  },
  '0x6B175474E89094C44Da98b954EedeAC495271d0F': {
    address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    chainId: 1,
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    icon: 'https://assets.coingecko.com/coins/images/9956/standard/Badge_Dai.png?1696509996',
    decimals: 18,
    poolId: 3,
    poolAddress: '0x0Faf1d2d3CED330824de3B8200fc8dc6E397850d',
    coinGeckoId: 'dai',
  },
  '0x853d955aCEf822Db058eb8505911ED77F175b99e': {
    address: '0x853d955aCEf822Db058eb8505911ED77F175b99e',
    chainId: 1,
    name: 'Frax',
    symbol: 'FRAX',
    icon: 'https://assets.coingecko.com/coins/images/13422/standard/FRAX_icon.png?1696513182',
    decimals: 18,
    poolId: 7,
    poolAddress: '0xfA0F307783AC21C39E939ACFF795e27b650F6e68',
    coinGeckoId: 'frax',
  },
  '0x57Ab1ec28D129707052df4dF418D58a2D46d5f51': {
    address: '0x57Ab1ec28D129707052df4dF418D58a2D46d5f51',
    chainId: 1,
    name: 'Synth sUSD',
    symbol: 'sUSD',
    icon: 'https://assets.coingecko.com/coins/images/5013/standard/sUSD.png?1696505546',
    decimals: 18,
    poolId: 14,
    poolAddress: '0x590d4f8A68583639f215f675F3a259Ed84790580',
    coinGeckoId: 'nusd',
  },
  '0x5f98805A4E8be255a32880FDeC7F6728C6568bA0': {
    address: '0x5f98805A4E8be255a32880FDeC7F6728C6568bA0',
    chainId: 1,
    name: 'LUSD Stablecoin',
    symbol: 'LUSD',
    icon: 'https://assets.coingecko.com/coins/images/14666/standard/Group_3.png?1696514341',
    decimals: 6,
    poolId: 15,
    poolAddress: '0xE8F55368C82D38bbbbDb5533e7F56AfC2E978CC2',
    coinGeckoId: 'liquity-usd',
  },
  '0x8D6CeBD76f18E1558D4DB88138e2DeFB3909fAD6': {
    address: '0x8D6CeBD76f18E1558D4DB88138e2DeFB3909fAD6',
    chainId: 1,
    name: 'Mai Stablecoin',
    symbol: 'MAI',
    icon: 'https://assets.coingecko.com/coins/images/15264/standard/mimatic-red.png?1696514916',
    decimals: 6,
    poolId: 16,
    poolAddress: '0x9cef9a0b1bE0D289ac9f4a98ff317c33EAA84eb8',
    coinGeckoId: 'mimatic',
  },
  '0x9E32b13ce7f2E80A01932B42553652E053D6ed8e': {
    address: '0x9E32b13ce7f2E80A01932B42553652E053D6ed8e',
    chainId: 1,
    name: 'Metis Token',
    symbol: 'Metis',
    icon: 'https://assets.coingecko.com/coins/images/15595/standard/metis.jpeg?1696515230',
    decimals: 18,
    poolId: 17,
    poolAddress: '0xd8772edBF88bBa2667ed011542343b0eDDaCDa47',
    coinGeckoId: 'metis-token',
  },
};
