import { useState } from 'react';
import { Contract } from '@ethersproject/contracts';
import { JsonRpcProvider, Web3Provider } from '@ethersproject/providers';
import { BigNumber } from 'bignumber.js';
import { useAccount } from 'wagmi';

export default function useEndPoint() {
  const { address: account } = useAccount();
  const [fee, setFee] = useState();
  const getQouteInfo = async ({ targetToken, chain, targetChain }) => {
    if (!targetToken || !chain || !targetChain) return;
    const provider = new JsonRpcProvider(chain.rpcUrls.default);
    const RouterContract = new Contract(
      chain.router,
      [
        {
          inputs: [
            { internalType: 'uint16', name: '_dstChainId', type: 'uint16' },
            { internalType: 'uint8', name: '_functionType', type: 'uint8' },
            { internalType: 'bytes', name: '_toAddress', type: 'bytes' },
            {
              internalType: 'bytes',
              name: '_transferAndCallPayload',
              type: 'bytes',
            },
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'dstGasForCall',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: 'dstNativeAmount',
                  type: 'uint256',
                },
                {
                  internalType: 'bytes',
                  name: 'dstNativeAddr',
                  type: 'bytes',
                },
              ],
              internalType: 'struct IStargateRouter.lzTxObj',
              name: '_lzTxParams',
              type: 'tuple',
            },
          ],
          name: 'quoteLayerZeroFee',
          outputs: [
            { internalType: 'uint256', name: '', type: 'uint256' },
            { internalType: 'uint256', name: '', type: 'uint256' },
          ],
          stateMutability: 'view',
          type: 'function',
        },
      ],
      provider
    );
    const res = await RouterContract.quoteLayerZeroFee(
      targetChain.dstId,
      1,
      targetToken.address,
      '0x',
      {
        dstGasForCall: 0,
        dstNativeAmount: 0,
        dstNativeAddr: '0x0000000000000000000000000000000000000001',
      }
    );
    setFee(new BigNumber(res[0]._hex).toString());
    return new BigNumber(res[0]._hex).div(10 ** chain.nativeCurrency.decimals);
  };
  const swap = async ({
    chain,
    token,
    targetChain,
    targetToken,
    amount,
    destination,
    onSuccess,
  }) => {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner(account);
    const _amount = new BigNumber(amount).multipliedBy(10 ** token.decimals);
    const RouterContract = token.isNative
      ? new Contract(
          chain.ethRouter,
          [
            {
              inputs: [
                {
                  internalType: 'uint16',
                  name: '_dstChainId',
                  type: 'uint16',
                },
                {
                  internalType: 'address payable',
                  name: '_refundAddress',
                  type: 'address',
                },
                { internalType: 'bytes', name: '_toAddress', type: 'bytes' },
                {
                  internalType: 'uint256',
                  name: '_amountLD',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: '_minAmountLD',
                  type: 'uint256',
                },
              ],
              name: 'swapETH',
              outputs: [],
              stateMutability: 'payable',
              type: 'function',
            },
          ],
          signer
        )
      : new Contract(
          chain.router,
          [
            {
              inputs: [
                {
                  internalType: 'uint16',
                  name: '_dstChainId',
                  type: 'uint16',
                },
                {
                  internalType: 'uint256',
                  name: '_srcPoolId',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: '_dstPoolId',
                  type: 'uint256',
                },
                {
                  internalType: 'address payable',
                  name: '_refundAddress',
                  type: 'address',
                },
                {
                  internalType: 'uint256',
                  name: '_amountLD',
                  type: 'uint256',
                },
                {
                  internalType: 'uint256',
                  name: '_minAmountLD',
                  type: 'uint256',
                },
                {
                  components: [
                    {
                      internalType: 'uint256',
                      name: 'dstGasForCall',
                      type: 'uint256',
                    },
                    {
                      internalType: 'uint256',
                      name: 'dstNativeAmount',
                      type: 'uint256',
                    },
                    {
                      internalType: 'bytes',
                      name: 'dstNativeAddr',
                      type: 'bytes',
                    },
                  ],
                  internalType: 'struct IStargateRouter.lzTxObj',
                  name: '_lzTxParams',
                  type: 'tuple',
                },
                { internalType: 'bytes', name: '_to', type: 'bytes' },
                { internalType: 'bytes', name: '_payload', type: 'bytes' },
              ],
              name: 'swap',
              outputs: [],
              stateMutability: 'payable',
              type: 'function',
            },
          ],
          signer
        );
    const method = token.isNative ? 'swapEth' : 'swap';
    const params = token.isNative
      ? [
          targetChain.dstId,
          account,
          account,
          _amount.toString(),
          _amount.multipliedBy(0.995).toString(),
          { value: fee },
        ]
      : [
          targetChain.dstId,
          token.poolId,
          targetToken.poolId,
          account,
          _amount.toString(),
          _amount.multipliedBy(0.995).toString(),
          { dstGasForCall: 0, dstNativeAmount: 0, dstNativeAddr: '0x' },
          destination || account,
          '0x',
          { value: fee },
        ];
    const tx = await RouterContract[method](...params);
    const res = await tx.wait();
    if (res.status === 1) {
      onSuccess(tx.hash);
      const bridgeTxs = localStorage.getItem('bridgeTxs') || '{}';
      const _bridgeTxs = JSON.parse(bridgeTxs);
      _bridgeTxs[tx.hash] = {
        amount,
        label: `${chain.name} -> ${targetChain.name} Chain`,
        symbol: token.symbol,
        tx: tx.hash,
        isStargate: true,
      };
      localStorage.setItem('bridgeTxs', JSON.stringify(_bridgeTxs));
    }
  };
  return {
    getQouteInfo,
    swap,
  };
}
