export default {
  '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA': {
    address: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
    chainId: 8453,
    name: 'USDC',
    symbol: 'USDC',
    icon: 'https://assets.coingecko.com/coins/images/6319/standard/usdc.png?1696506694',
    decimals: 6,
    poolId: 1,
    poolAddress: '0x4c80E24119CFB836cdF0a6b53dc23F04F7e652CA',
    coinGeckoId: 'usd-coin',
  },
  '0x224D8Fd7aB6AD4c6eb4611Ce56EF35Dec2277F03': {
    address: '0x224D8Fd7aB6AD4c6eb4611Ce56EF35Dec2277F03',
    chainId: 8453,
    name: 'ETH',
    symbol: 'ETH',
    icon: 'https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628',
    decimals: 18,
    poolId: 13,
    poolAddress: '0x28fc411f9e1c480AD312b3d9C60c22b965015c6B',
    coinGeckoId: 'ethereum',
  },
};
