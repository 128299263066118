import { Flex } from 'antd';

export default function Dex({ dex, gasCost, token }) {
  return (
    <Flex className='dex' justify='space-between'>
      <Flex align='center' gap={6}>
        <img src={dex.icon} className='dex-icon' />
        <Flex vertical gap={6}>
          <div className='dex-name'>{dex.name}</div>
          <Flex gap={4}>
            {dex.tags &&
              dex.tags
                .filter((tag) => ['best', 'faster'].includes(tag))
                .map((tag) => {
                  if (tag === 'best')
                    return (
                      <div className='tag best' key={tag}>
                        Best Return
                      </div>
                    );
                  if (tag === 'faster')
                    return (
                      <div className='tag fast' key={tag}>
                        Fastest
                      </div>
                    );
                })}
          </Flex>
        </Flex>
      </Flex>
      <Flex vertical gap={6}>
        <Flex gap={4}>
          {token && <img src={token.icon} className='result-icon' />}
          <span>{dex.amount ? '~' + dex.amount : '-'}</span>
          <span>{token?.symbol}</span>
        </Flex>
        <div className='result-desc'>{`~${dex.time} | Gas $${
          gasCost || '-'
        }`}</div>
      </Flex>
    </Flex>
  );
}
