export default {
  '0x28a92dde19D9989F39A49905d7C9C2FAc7799bDf': {
    address: '0x28a92dde19D9989F39A49905d7C9C2FAc7799bDf',
    chainId: 1088,
    name: 'USDC',
    symbol: 'USDC',
    icon: 'https://assets.coingecko.com/coins/images/6319/standard/usdc.png?1696506694',
    decimals: 6,
    poolId: 21,
    poolAddress: '0xc647ce76ec30033aa319d472ae9f4462068f2ad7',
    coinGeckoId: 'usd-coin',
  },
};
