import { useEffect, useState } from 'react';
import tokensConfig from '../configs/tokens';

export default function useTokens() {
  const [tokens, setTokens] = useState({});
  const getTokens = async () => {
    setTokens(tokensConfig);
  };
  useEffect(() => {
    getTokens();
  }, []);
  return { tokens };
}
