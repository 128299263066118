export default {
  '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000': {
    address: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
    chainId: 1088,
    name: 'Metis',
    symbol: 'Metis',
    icon: 'https://assets.coingecko.com/coins/images/15595/standard/metis.jpeg?1696515230',
    decimals: 18,
    poolId: 17,
    poolAddress: '0xAad094F6A75A14417d39f04E690fC216f080A41a',
    coinGeckoId: 'metis-token',
  },
  '0xbb06dca3ae6887fabf931640f67cab3e3a16f4dc': {
    address: '0xbb06dca3ae6887fabf931640f67cab3e3a16f4dc',
    chainId: 1088,
    name: 'USDT Token',
    symbol: 'USDT',
    icon: 'https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661',
    decimals: 6,
    poolId: 19,
    poolAddress: '0x2b60473a7C41Deb80EDdaafD5560e963440eb632',
    coinGeckoId: 'tether',
  },
};
