import DownArrowIcon from '../components/DownArrowIcon';

export default function Currency({
  token,
  chain,
  disabled,
  onTokenClick,
  onChainToken,
}) {
  return (
    <div className='currency'>
      <div
        className={`currency-tokens ${disabled && 'disabled'}`}
        onClick={onTokenClick}
      >
        <div className='currency-label'>Token</div>
        <div className='currency-token-wrapper'>
          <div className='currency-token'>
            {token ? (
              <>
                <img className='currency-token-logo' src={token.icon} />
                <div className='currency-token-symbol'>{token.symbol}</div>
              </>
            ) : (
              <>
                <div className='empty-token-logo'></div>
                <div className='empty-label'>Select</div>
              </>
            )}
          </div>
          {!disabled && (
            <div className='down-arrow'>
              <DownArrowIcon />
            </div>
          )}
        </div>
      </div>
      <div className='currency-chains' onClick={onChainToken}>
        <div className='currency-label'>Network</div>
        <div className='currency-token-wrapper'>
          <div className='currency-token'>
            {chain ? (
              <>
                <img className='currency-token-logo' src={chain.icon} />
                <div className='currency-token-symbol'>{chain.name}</div>
              </>
            ) : (
              <>
                <div className='empty-chain-logo'></div>
                <div className='empty-label'>Select</div>
              </>
            )}
          </div>
          <div className='down-arrow'>
            <DownArrowIcon />
          </div>
        </div>
      </div>
    </div>
  );
}
