export default {
  '0xc2132D05D31c914a87C6611C10748AEb04B58e8F': {
    address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    chainId: 137,
    name: 'Tether USD',
    symbol: 'USDT',
    icon: 'https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661',
    decimals: 6,
    poolId: 2,
    poolAddress: '0x29e38769f23701A2e4A8Ef0492e19dA4604Be62c',
    coinGeckoId: 'tether',
  },
  '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174': {
    address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    chainId: 137,
    name: 'USDC',
    symbol: 'USDC',
    icon: 'https://assets.coingecko.com/coins/images/6319/standard/usdc.png?1696506694',
    decimals: 6,
    poolId: 1,
    poolAddress: '0x1205f31718499dBf1fCa446663B532Ef87481fe1',
    coinGeckoId: 'usd-coin',
  },
  '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063': {
    address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    chainId: 137,
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    icon: 'https://assets.coingecko.com/coins/images/9956/standard/Badge_Dai.png?1696509996',
    decimals: 18,
    poolId: 3,
    poolAddress: '0x1c272232Df0bb6225dA87f4dEcD9d37c32f63Eea',
    coinGeckoId: 'dai',
  },
  '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1': {
    address: '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    chainId: 137,
    name: 'Mai Stablecoin',
    symbol: 'MAI',
    icon: 'https://assets.coingecko.com/coins/images/15264/standard/mimatic-red.png?1696514916',
    decimals: 18,
    poolId: 16,
    poolAddress: '0x8736f92646B2542B3e5F3c63590cA7Fe313e283B',
    coinGeckoId: 'mimatic',
  },
};
