import useChains from '../hooks/useChains';

export default function TokenLogo({ token }) {
  const chains = useChains();
  const chain = chains[token.chainId];
  return (
    <div className='tokenLogoWrap'>
      <img src={token.icon} alt={token.name} className='tokenLogo' />
      <img src={chain.icon} alt={chain.name} className='tokenChainLogo' />
    </div>
  );
}
