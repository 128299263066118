import { Modal, Flex } from 'antd';
import BigNumber from 'bignumber.js';
import Dex from './Dex';

export default function RoutesModal({
  isOpen,
  setIsOpen,
  currentDex,
  dexs,
  token,
  gasCostValues,
  onChangeDex,
}) {
  return (
    <Modal
      open={isOpen}
      footer={null}
      onCancel={() => {
        setIsOpen(false);
      }}
      title={`${dexs.length} ROUTES`}
      width={500}
    >
      <div className='modalContent'>
        <Flex vertical gap={6}>
          {dexs.map((dex) => (
            <div
              className={`dex-wrapper ${
                dex.name === currentDex?.name && 'active'
              }`}
              key={dex.name}
              onClick={() => {
                onChangeDex(dex);
                setIsOpen(false);
              }}
            >
              <Dex dex={dex} token={token} gasCost={gasCostValues[dex.name]} />
            </div>
          ))}
        </Flex>
      </div>
    </Modal>
  );
}
