export default {
  '0xb69c8CBCD90A39D8D3d3ccf0a3E968511C3856A0': {
    address: '0xb69c8CBCD90A39D8D3d3ccf0a3E968511C3856A0',
    chainId: 10,
    name: 'ETH',
    symbol: 'ETH',
    icon: 'https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628',
    decimals: 18,
    poolId: 13,
    poolAddress: '0xd22363e3762cA7339569F3d33EADe20127D5F98C',
    coinGeckoId: 'ethereum',
  },
  '0x7F5c764cBc14f9669B88837ca1490cCa17c31607': {
    address: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
    chainId: 10,
    name: 'USDC',
    symbol: 'USDC',
    icon: 'https://assets.coingecko.com/coins/images/6319/standard/usdc.png?1696506694',
    decimals: 6,
    poolId: 1,
    poolAddress: '0xDecC0c09c3B5f6e92EF4184125D5648a66E35298',
    coinGeckoId: 'usd-coin',
  },
  '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1': {
    address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    chainId: 10,
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    icon: 'https://assets.coingecko.com/coins/images/9956/standard/Badge_Dai.png?1696509996',
    decimals: 18,
    poolId: 3,
    poolAddress: '0x165137624F1f692e69659f944BF69DE02874ee27',
    coinGeckoId: 'dai',
  },
  '0x2E3D870790dC77A83DD1d18184Acc7439A53f475': {
    address: '0x2E3D870790dC77A83DD1d18184Acc7439A53f475',
    chainId: 10,
    name: 'Frax',
    symbol: 'FRAX',
    icon: 'https://assets.coingecko.com/coins/images/13422/standard/FRAX_icon.png?1696513182',
    decimals: 18,
    poolId: 7,
    poolAddress: '0x368605D9C6243A80903b9e326f1Cddde088B8924',
    coinGeckoId: 'frax',
  },
  '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9': {
    address: '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9',
    chainId: 10,
    name: 'Synth sUSD',
    symbol: 'sUSD',
    icon: 'https://assets.coingecko.com/coins/images/5013/standard/sUSD.png?1696505546',
    decimals: 18,
    poolId: 14,
    poolAddress: '0x2F8bC9081c7FCFeC25b9f41a50d97EaA592058ae',
    coinGeckoId: 'nusd',
  },
  '0xc40F949F8a4e094D1b49a23ea9241D289B7b2819': {
    address: '0xc40F949F8a4e094D1b49a23ea9241D289B7b2819',
    chainId: 10,
    name: 'LUSD Stablecoin',
    symbol: 'LUSD',
    icon: 'https://assets.coingecko.com/coins/images/14666/standard/Group_3.png?1696514341',
    decimals: 18,
    poolId: 15,
    poolAddress: '0x3533F5e279bDBf550272a199a223dA798D9eff78',
    coinGeckoId: 'liquity-usd',
  },
  '0xdFA46478F9e5EA86d57387849598dbFB2e964b02': {
    address: '0xdFA46478F9e5EA86d57387849598dbFB2e964b02',
    chainId: 10,
    name: 'Mai Stablecoin',
    symbol: 'MAI',
    icon: 'https://assets.coingecko.com/coins/images/15264/standard/mimatic-red.png?1696514916',
    decimals: 18,
    poolId: 16,
    poolAddress: '0x5421FA1A48f9FF81e4580557E86C7C0D24C18036',
    coinGeckoId: 'mimatic',
  },
};
