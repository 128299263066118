export default {
  '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E': {
    address: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    chainId: 43114,
    name: 'USDC',
    symbol: 'USDC',
    icon: 'https://assets.coingecko.com/coins/images/6319/standard/usdc.png?1696506694',
    decimals: 6,
    poolId: 1,
    poolAddress: '0x1205f31718499dBf1fCa446663B532Ef87481fe1',
    coinGeckoId: 'usd-coin',
  },
  '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7': {
    address: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
    chainId: 43114,
    name: 'Tether USD',
    symbol: 'USDT',
    icon: 'https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661',
    decimals: 6,
    poolId: 2,
    poolAddress: '0x29e38769f23701A2e4A8Ef0492e19dA4604Be62c',
    coinGeckoId: 'tether',
  },
  '0xD24C2Ad096400B6FBcd2ad8B24E7acBc21A1da64': {
    address: '0xD24C2Ad096400B6FBcd2ad8B24E7acBc21A1da64',
    chainId: 43114,
    name: 'Frax',
    symbol: 'FRAX',
    icon: 'https://assets.coingecko.com/coins/images/13422/standard/FRAX_icon.png?1696513182',
    decimals: 18,
    poolId: 7,
    poolAddress: '0x1c272232Df0bb6225dA87f4dEcD9d37c32f63Eea',
    coinGeckoId: 'frax',
  },
  '0x5c49b268c9841AFF1Cc3B0a418ff5c3442eE3F3b': {
    address: '0x5c49b268c9841AFF1Cc3B0a418ff5c3442eE3F3b',
    chainId: 43114,
    name: 'Mai Stablecoin',
    symbol: 'MAI',
    icon: 'https://assets.coingecko.com/coins/images/15264/standard/mimatic-red.png?1696514916',
    decimals: 18,
    poolId: 16,
    poolAddress: '0x8736f92646B2542B3e5F3c63590cA7Fe313e283B',
    coinGeckoId: 'mimatic',
  },
};
