export default {
  '0x55d398326f99059fF775485246999027B3197955': {
    address: '0x55d398326f99059fF775485246999027B3197955',
    chainId: 56,
    name: 'Tether USD',
    symbol: 'USDT',
    icon: 'https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661',
    decimals: 18,
    poolId: 2,
    poolAddress: '0x9aA83081AA06AF7208Dcc7A4cB72C94d057D2cda',
    coinGeckoId: 'tether',
  },
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56': {
    address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    chainId: 56,
    name: 'Binance-Peg BUSD Token',
    symbol: 'BUSD',
    icon: 'https://assets.coingecko.com/coins/images/31273/standard/new_binance-peg-busd.png?1696530096',
    decimals: 18,
    poolId: 5,
    poolAddress: '0x98a5737749490856b401DB5Dc27F522fC314A4e1',
    coinGeckoId: 'binance-peg-busd',
  },
  '0xd17479997F34dd9156Deef8F95A52D81D265be9c': {
    address: '0xd17479997F34dd9156Deef8F95A52D81D265be9c',
    chainId: 56,
    name: 'Decentralized USD',
    symbol: 'USDD',
    icon: 'https://assets.coingecko.com/coins/images/25380/standard/UUSD.jpg?1696524513',
    decimals: 18,
    poolId: 11,
    poolAddress: '0x4e145a589e4c03cBe3d28520e4BF3089834289Df',
    coinGeckoId: 'usdd',
  },
  '0x3F56e0c36d275367b8C502090EDF38289b3dEa0d': {
    address: '0x3F56e0c36d275367b8C502090EDF38289b3dEa0d',
    chainId: 56,
    name: 'Mai Stablecoin',
    symbol: 'MAI',
    icon: 'https://assets.coingecko.com/coins/images/15264/standard/mimatic-red.png?1696514916',
    decimals: 18,
    poolId: 16,
    poolAddress: '0x7BfD7f2498C4796f10b6C611D9db393D3052510C',
    coinGeckoId: 'mimatic',
  },
  '0xe552Fb52a4F19e44ef5A967632DBc320B0820639': {
    address: '0xe552Fb52a4F19e44ef5A967632DBc320B0820639',
    chainId: 56,
    name: 'Metis Token',
    symbol: 'Metis',
    icon: 'https://assets.coingecko.com/coins/images/15595/standard/metis.jpeg?1696515230',
    decimals: 18,
    poolId: 17,
    poolAddress: '0xD4CEc732b3B135eC52a3c0bc8Ce4b8cFb9dacE46',
    coinGeckoId: 'metis-token',
  },
};
