import { useState } from 'react';
import BigNumber from 'bignumber.js';
import useEndPoint from './useEndPoint';
import useBaseBridge from './useBaseBridge';

const dexs = {
  Stargate: {
    icon: '/stargate.svg',
    name: 'Stargate',
    tags: ['faster'],
  },
  BaseBridge: {
    icon: 'https://icons-ckg.pages.dev/stargate-light/networks/base.svg',
    name: 'BaseBridge',
    tags: ['best'],
    router: '0x4200000000000000000000000000000000000010',
    ethRouter: '0x4200000000000000000000000000000000000016',
  },
};

export default function useBestRoute() {
  const { getQouteInfo, swap: stargateSwap } = useEndPoint();
  const { quote: baseQuote, swap: baseSwap } = useBaseBridge();
  const [checking, setChecking] = useState(false);
  const [swaping, setSwaping] = useState(false);

  const getBestRoute = async ({
    token,
    chain,
    targetToken,
    targetChain,
    amount,
  }) => {
    try {
      setChecking(true);
      if (
        (chain.id === 8453 && targetChain.id === 1) ||
        (chain.id === 1 && targetChain.id === 8453)
      ) {
        const [res1, res2] = await Promise.all([
          getQouteInfo({ targetToken, chain, targetChain }),
          baseQuote({ token, targetToken, amount, chain }),
        ]);
        setChecking(false);
        const result = [dexs.Stargate, dexs.BaseBridge];
        result[0].time = '1min';
        result[0].amount = new BigNumber(amount || 0)
          .multipliedBy(0.995)
          .toFixed(2, 1);
        result[0].gasCost = res1;
        result[1].time = chain.id === 1 ? '5min' : '7days';
        result[1].amount = new BigNumber(amount || 0).toFixed(2);
        result[1].gasCost = res2;
        return result;
      }
      const response = await getQouteInfo({ targetToken, chain, targetChain });
      setChecking(false);
      return [
        {
          ...dexs.Stargate,
          tags: ['best', 'faster'],
          time: '1min',
          amount: new BigNumber(amount || 0).multipliedBy(0.995).toFixed(2, 1),
          gasCost: response,
        },
      ];
    } catch (err) {
      setChecking(false);
      return [];
    }
  };

  const swap = async function ({
    token,
    chain,
    targetToken,
    targetChain,
    destination,
    amount,
    onSuccess,
    dexName,
  }) {
    try {
      setSwaping(true);
      if (dexName === 'Stargate') {
        await stargateSwap({
          chain: chain,
          token: token,
          targetChain: targetChain,
          targetToken: targetToken,
          amount,
          destination,
          onSuccess,
        });
      }
      if (dexName === 'BaseBridge') {
        await baseSwap({
          token,
          chain,
          targetChain,
          targetToken,
          amount,
          onSuccess,
        });
      }
      setSwaping(false);
    } catch (err) {
      setSwaping(false);
    }
  };

  return { getBestRoute, swap, checking, swaping };
}
