import { useState } from 'react';
import { useConnect, useAccount } from 'wagmi';
import { MetaMaskConnector } from '@wagmi/connectors/metaMask';
import Header from './components/Header';
import Swap from './components/Swap';
import Transactions from './components/Transactions';
import './App.css';

function App() {
  const { address, isConnected } = useAccount();
  const [tab, setTab] = useState(1);
  const { connect } = useConnect({
    connector: new MetaMaskConnector(),
  });
  return (
    <div className='App'>
      <Header connect={connect} isConnected={isConnected} address={address} />
      <div className='tabs'>
        <div
          className={`tab ${tab === 1 && 'active'}`}
          onClick={() => {
            setTab(1);
          }}
        >
          TRANSFER
        </div>
        <div
          className={`tab ${tab === 2 && 'active'}`}
          onClick={() => {
            setTab(2);
          }}
        >
          TRANSACTIONS
        </div>
      </div>
      <div className='mainWindow'>
        {tab === 1 && <Swap isConnected={isConnected} address={address} />}
        {tab === 2 && <Transactions />}
      </div>
    </div>
  );
}

export default App;
