export default function useChains() {
  return {
    1: {
      id: 1,
      dstId: 101,
      name: 'Ethereum',
      icon: 'https://icons-ckg.pages.dev/stargate-light/networks/ethereum.svg',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
        coinGeckoId: 'ethereum',
      },
      rpcUrls: {
        default: 'https://eth.llamarpc.com',
      },
      blockExplorers: {
        default: {
          name: 'etherscan',
          url: 'https://etherscan.io',
        },
      },
      router: '0x8731d54E9D02c286767d56ac03e8037C07e01e98',
      ethRouter: '0x150f94B44927F078737562f0fcF3C95c01Cc2376',
    },
    56: {
      id: 56,
      dstId: 102,
      name: 'BNB',
      icon: 'https://icons-ckg.pages.dev/stargate-light/networks/bsc.svg',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
        coinGeckoId: 'binancecoin',
      },
      rpcUrls: {
        default: 'https://binance.llamarpc.com',
      },
      blockExplorers: {
        default: {
          name: 'bscscan',
          url: 'https://bscscan.com',
        },
      },
      router: '0x4a364f8c717cAAD9A442737Eb7b8A55cc6cf18D8',
    },
    43114: {
      id: 43114,
      dstId: 106,
      name: 'Avalanche',
      icon: 'https://icons-ckg.pages.dev/stargate-light/networks/avalanche.svg',
      nativeCurrency: {
        name: 'AVAX',
        symbol: 'AVAX',
        decimals: 18,
        coinGeckoId: 'avalanche-2',
      },
      rpcUrls: {
        default: 'https://api.avax.network/ext/bc/C/rpc',
      },
      blockExplorers: {
        default: {
          name: 'snowtrace',
          url: 'https://snowtrace.io',
        },
      },
      router: '0x45A01E4e04F14f7A4a6702c74187c5F6222033cd',
    },
    137: {
      id: 137,
      dstId: 109,
      name: 'Polygon',
      icon: 'https://icons-ckg.pages.dev/stargate-light/networks/polygon.svg',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
        coinGeckoId: 'matic-network',
      },
      rpcUrls: {
        default: 'https://polygon.llamarpc.com',
      },
      blockExplorers: {
        default: {
          name: 'polygonscan',
          url: 'https://polygonscan.com',
        },
      },
      router: '0x45A01E4e04F14f7A4a6702c74187c5F6222033cd',
    },
    42161: {
      id: 42161,
      dstId: 110,
      name: 'Arbitrum',
      icon: 'https://icons-ckg.pages.dev/stargate-light/networks/arbitrum.svg',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
        coinGeckoId: 'ethereum',
      },
      rpcUrls: {
        default: 'https://arb1.arbitrum.io/rpc',
      },
      blockExplorers: {
        default: {
          name: 'arbiscan',
          url: 'https://arbiscan.io',
        },
      },
      router: '0x53Bf833A5d6c4ddA888F69c22C88C9f356a41614',
      ethRouter: '0xbf22f0f184bCcbeA268dF387a49fF5238dD23E40',
    },
    10: {
      id: 10,
      name: 'Optimism',
      dstId: 111,
      icon: 'https://icons-ckg.pages.dev/stargate-light/networks/optimism.svg',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
        coinGeckoId: 'ethereum',
      },
      rpcUrls: {
        default: 'https://rpc.ankr.com/optimism',
      },
      blockExplorers: {
        default: {
          name: 'optimistic scan',
          url: 'https://optimistic.etherscan.io/',
        },
      },
      router: '0xB0D502E938ed5f4df2E681fE6E419ff29631d62b',
      ethRouter: '0xB49c4e680174E331CB0A7fF3Ab58afC9738d5F8b',
    },
    250: {
      id: 250,
      dstId: 112,
      name: 'Fantom',
      icon: 'https://icons-ckg.pages.dev/stargate-light/networks/fantom.svg',
      nativeCurrency: {
        name: 'FTM',
        symbol: 'FTM',
        decimals: 18,
        coinGeckoId: 'fantom',
      },
      rpcUrls: {
        default: 'https://rpcapi.fantom.network',
      },
      blockExplorers: {
        default: {
          name: 'ftmscan',
          url: 'https://ftmscan.com',
        },
      },
      router: '0xAf5191B0De278C7286d6C7CC6ab6BB8A73bA2Cd6',
    },
    1088: {
      id: 1088,
      dstId: 151,
      name: 'Metis',
      icon: 'https://icons-ckg.pages.dev/stargate-light/networks/metis.svg',
      nativeCurrency: {
        name: 'METIS',
        symbol: 'METIS',
        decimals: 18,
        coinGeckoId: 'metis-token',
      },
      rpcUrls: {
        default: 'https://andromeda.metis.io/?owner=1088',
      },
      blockExplorers: {
        default: {
          name: 'andromeda explorer',
          url: 'https://andromeda-explorer.metis.io',
        },
      },
      router: '0x2F6F07CDcf3588944Bf4C42aC74ff24bF56e7590',
    },
    // 1101: {
    //   id: 1101,
    //   name: 'zkEVM',
    //   icon: 'https://icons-ckg.pages.dev/stargate-light/networks/zkevm.svg',
    //   nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    //   rpcUrls: {
    //     default: 'https://zkevm-rpc.com',
    //   },
    //   blockExplorers: {
    //     default: {
    //       name: 'zkevm polygonscan',
    //       url: 'https://zkevm.polygonscan.com',
    //     },
    //   },
    // },
    // 324: {
    //   id: 324,
    //   name: 'zkSync',
    //   icon: 'https://icons-ckg.pages.dev/stargate-light/networks/zksync.svg',
    //   nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    //   rpcUrls: {
    //     default: 'https://mainnet.era.zksync.io',
    //   },
    //   blockExplorers: {
    //     default: {
    //       name: 'explorer zksync',
    //       url: 'https://explorer.zksync.io/',
    //     },
    //   },
    // },
    8453: {
      id: 8453,
      dstId: 184,
      name: 'Base',
      icon: 'https://icons-ckg.pages.dev/stargate-light/networks/base.svg',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
        coinGeckoId: 'ethereum',
      },
      rpcUrls: {
        default: 'https://developer-access-mainnet.base.org',
      },
      blockExplorers: {
        default: {
          name: 'basescan',
          url: 'https://basescan.org/',
        },
      },
      router: '0x45f1A95A4D3f3836523F5c83673c797f4d4d263B',
      ethRouter: '0x50B6EbC2103BFEc165949CC946d739d5650d7ae4',
    },
    59144: {
      id: 59144,
      dstId: 183,
      name: 'Linea',
      icon: 'https://icons-ckg.pages.dev/stargate-light/networks/linea.svg',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
        coinGeckoId: 'ethereum',
      },
      rpcUrls: {
        default: 'https://linea.blockpi.network/v1/rpc/public',
      },
      blockExplorers: {
        default: {
          name: 'lineascan',
          url: 'https://lineascan.build',
        },
      },
      router: '0x2F6F07CDcf3588944Bf4C42aC74ff24bF56e7590',
      ethRouter: '0x8731d54E9D02c286767d56ac03e8037C07e01e98',
    },
    2222: {
      id: 2222,
      dstId: 177,
      name: 'Kava',
      icon: 'https://icons-ckg.pages.dev/stargate-light/networks/kava.svg',
      nativeCurrency: {
        name: 'KAVA',
        symbol: 'KAVA',
        decimals: 18,
        coinGeckoId: 'kava',
      },
      rpcUrls: {
        default: 'https://evm.kava.io',
      },
      blockExplorers: {
        default: {
          name: 'kavascan',
          url: 'https://kavascan.com',
        },
      },
      router: '0x2F6F07CDcf3588944Bf4C42aC74ff24bF56e7590',
    },
  };
}
