export default {
  '0x82CbeCF39bEe528B5476FE6d1550af59a9dB6Fc0': {
    address: '0x82CbeCF39bEe528B5476FE6d1550af59a9dB6Fc0',
    chainId: 42161,
    name: 'ETH',
    symbol: 'ETH',
    icon: 'https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628',
    decimals: 18,
    poolId: 13,
    poolAddress: '0x915A55e36A01285A14f05dE6e81ED9cE89772f8e',
    coinGeckoId: 'ethereum',
  },
  '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8': {
    address: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    chainId: 42161,
    name: 'USDC',
    symbol: 'USDC',
    icon: 'https://assets.coingecko.com/coins/images/6319/standard/usdc.png?1696506694',
    decimals: 6,
    poolId: 1,
    poolAddress: '0x892785f33CdeE22A30AEF750F285E18c18040c3e',
    coinGeckoId: 'usd-coin',
  },
  '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9': {
    address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    chainId: 42161,
    name: 'Tether USD',
    symbol: 'USDT',
    icon: 'https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661',
    decimals: 6,
    poolId: 2,
    poolAddress: '0xB6CfcF89a7B22988bfC96632aC2A9D6daB60d641',
    coinGeckoId: 'tether',
  },
  '0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F': {
    address: '0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F',
    chainId: 42161,
    name: 'Frax',
    symbol: 'FRAX',
    icon: 'https://assets.coingecko.com/coins/images/13422/standard/FRAX_icon.png?1696513182',
    decimals: 18,
    poolId: 7,
    poolAddress: '0xaa4BF442F024820B2C28Cd0FD72b82c63e66F56C',
    coinGeckoId: 'frax',
  },
  '0x3F56e0c36d275367b8C502090EDF38289b3dEa0d': {
    address: '0x3F56e0c36d275367b8C502090EDF38289b3dEa0d',
    chainId: 42161,
    name: 'Mai Stablecoin',
    symbol: 'MAI',
    icon: 'https://assets.coingecko.com/coins/images/15264/standard/mimatic-red.png?1696514916',
    decimals: 18,
    poolId: 16,
    poolAddress: '0xF39B7Be294cB36dE8c510e267B82bb588705d977',
    coinGeckoId: 'mimatic',
  },
  '0x93b346b6BC2548dA6A1E7d98E9a421B42541425b': {
    address: '0x93b346b6BC2548dA6A1E7d98E9a421B42541425b',
    chainId: 42161,
    name: 'LUSD Stablecoin',
    symbol: 'LUSD',
    icon: 'https://assets.coingecko.com/coins/images/14666/standard/Group_3.png?1696514341',
    decimals: 18,
    poolId: 15,
    poolAddress: '0x600E576F9d853c95d58029093A16EE49646F3ca5',
    coinGeckoId: 'liquity-usd',
  },
};
