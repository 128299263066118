import { useState, useEffect } from 'react';
import { Contract } from '@ethersproject/contracts';
import { JsonRpcProvider } from '@ethersproject/providers';
import { useAccount } from 'wagmi';
import BigNumber from 'bignumber.js';

export default function useTokenBalance(token, chainOne, update) {
  const [balance, setBalance] = useState();
  const [loading, setLoading] = useState(false);
  const { address: account } = useAccount();

  useEffect(() => {
    const getBalance = async () => {
      setLoading(true);
      try {
        const provider = new JsonRpcProvider(chainOne.rpcUrls.default);
        const TokenContract = new Contract(
          token.address,
          [
            {
              constant: true,
              inputs: [
                {
                  name: '_owner',
                  type: 'address',
                },
              ],
              name: 'balanceOf',
              outputs: [
                {
                  name: 'balance',
                  type: 'uint256',
                },
              ],
              payable: false,
              stateMutability: 'view',
              type: 'function',
            },
          ],
          provider
        );
        const res = await TokenContract.balanceOf(account);
        setBalance(new BigNumber(res._hex).div(10 ** token.decimals));
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    if (token?.address && account) getBalance();
  }, [token?.address, account, update]);
  return { balance, loading };
}
