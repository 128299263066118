export default {
  '0x919C1c267BC06a7039e03fcc2eF738525769109c': {
    address: '0x919C1c267BC06a7039e03fcc2eF738525769109c',
    chainId: 2222,
    name: 'Tether USD',
    symbol: 'USDT',
    icon: 'https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661',
    decimals: 6,
    poolId: 2,
    poolAddress: '0xAad094F6A75A14417d39f04E690fC216f080A41a',
    coinGeckoId: 'tether',
  },
};
