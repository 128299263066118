import { useState, useEffect, useRef } from 'react';
import { Flex, Tag } from 'antd';
import { CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import formatRoundTime from '../utils/formatRoundTime';

function CrossChainProcess(props) {
  const { tx, onClose } = props;
  const [time, setTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();
  const timerCancelRef = useRef(null);
  const distanceRef = useRef(300);
  const getStargateStatus = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api-mainnet.layerzero-scan.com/tx/${tx.tx}`
      );
      const result = await response.json();
      const status =
        result.messages[0].status === 'DELIVERED' ? 'success' : 'secondary';
      setStatus(status);
      const bridgeTxs = localStorage.getItem('bridgeTxs') || '{}';
      const _bridgeTxs = JSON.parse(bridgeTxs);
      _bridgeTxs[tx].status = status;
      localStorage.setItem('bridgeTxs', JSON.stringify(_bridgeTxs));
      setLoading(false);
      console.log('status', status);
      if (status === 'success') {
        return;
      }
      clearInterval(timerCancelRef.current);
      setTime(formatRoundTime(distanceRef.current));
      timerCancelRef.current = setInterval(() => {
        distanceRef.current -= 1;
        setTime(formatRoundTime(distanceRef.current));
      }, 1000);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tx.isStargate) getStargateStatus();
  }, [tx]);

  return (
    <div className='transaction'>
      <Flex justify='space-between'>
        <Flex gap={6}>
          <div className='transaction-label'>{tx.label}</div>
          <div>
            {tx.amount} {tx.symbol}
          </div>
        </Flex>
        <div
          className='close-text'
          onClick={() => {
            onClose();
          }}
        >
          <CloseCircleOutlined />
        </div>
      </Flex>
      <Flex justify='space-between'>
        <Flex gap={6}>
          <div>
            {tx.isStargate && (
              <Tag
                icon={<img src='/stargate.svg' className='tag-icon' />}
                color='#000'
              >
                Stargate
              </Tag>
            )}
            {tx.isBaseBridge && (
              <Tag
                icon={
                  <img
                    src='https://icons-ckg.pages.dev/stargate-light/networks/base.svg'
                    className='tag-icon'
                  />
                }
                color='rgb(55,115,245)'
              >
                Base Bridge
              </Tag>
            )}
          </div>
        </Flex>
        {loading ? (
          <LoadingOutlined />
        ) : tx.isStargate ? (
          <div
            style={{
              color: status === 'success' ? 'green' : '#91caff',
            }}
          >
            {status === 'success' ? (
              'Complete'
            ) : (
              <span className='color'>{time}</span>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </Flex>
    </div>
  );
}

export default function Transactions() {
  const [txs, setTxs] = useState([]);

  useEffect(() => {
    const bridgeTxs = localStorage.getItem('bridgeTxs') || '{}';
    const _bridgeTxs = Object.values(JSON.parse(bridgeTxs));
    setTxs(_bridgeTxs);
  }, []);
  return (
    <div className='tradeBox'>
      <div className='panel-shadow'></div>
      <div className='panel-content'>
        <div className='tradeBoxHeader'>
          <h4>TRANSACTIONS</h4>
        </div>
        <div className='transactions'>
          {txs.map((tx) => (
            <CrossChainProcess
              tx={tx}
              key={tx.tx}
              onClose={() => {
                const bridgeTxs = localStorage.getItem('bridgeTxs') || '{}';
                const _bridgeTxs = JSON.parse(bridgeTxs);
                delete _bridgeTxs[tx.tx];
                localStorage.setItem('bridgeTxs', JSON.stringify(_bridgeTxs));
                setTxs(Object.values(_bridgeTxs));
              }}
            />
          ))}
          {txs.length === 0 && (
            <div style={{ textAlign: 'center', color: 'rgb(100 116 139)' }}>
              No Transactions.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
