import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { Contract } from '@ethersproject/contracts';
import { JsonRpcProvider, Web3Provider } from '@ethersproject/providers';
import BigNumber from 'bignumber.js';

function getSpender({ token, chain, currentDex }) {
  if (currentDex.name === 'Stargate') {
    return token.isNative ? chain.ethRouter : chain.router;
  }
  if (currentDex.name === 'BaseBridge') {
    return token.isNative ? currentDex.ethRouter : currentDex.router;
  }
}

export default function useApprove({ token, amount, chain, currentDex }) {
  const [approved, setApproved] = useState(false);
  const [approving, setApproving] = useState(false);
  const { address: account } = useAccount();

  const checkApproved = async () => {
    const provider = new JsonRpcProvider(chain.rpcUrls.default);
    const spender = getSpender({ token, chain, currentDex });
    const TokenContract = new Contract(
      token.address,
      [
        {
          inputs: [
            { internalType: 'address', name: '', type: 'address' },
            { internalType: 'address', name: '', type: 'address' },
          ],
          name: 'allowance',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
        },
      ],
      provider
    );
    const allowanceRes = await TokenContract.allowance(account, spender);
    const needApproved = new BigNumber(allowanceRes._hex)
      .div(10 ** token.decimals)
      .lt(amount);
    setApproved(!needApproved);
  };

  const approve = async () => {
    setApproving(true);
    try {
      const provider = new Web3Provider(window.ethereum);
      const signer = provider.getSigner(account);
      const spender = getSpender({ token, chain, currentDex });
      const TokenContract = new Contract(
        token.address,
        [
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'value', type: 'uint256' },
            ],
            name: 'approve',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function',
          },
        ],
        signer
      );
      const tx = await TokenContract.approve(
        spender,
        new BigNumber(amount).multipliedBy(10 ** token.decimals).toString()
      );
      const res = await tx.wait();
      setApproving(false);
      if (res.status === 1) setApproved(true);
    } catch (err) {
      setApproving(false);
    }
  };

  useEffect(() => {
    if (token && amount && currentDex && chain) checkApproved();
  }, [token, amount, currentDex, chain]);

  return { approved, approve, approving };
}
