import { useEffect, useState } from 'react';
import { Flex } from 'antd';
import BigNumber from 'bignumber.js';
import RoutesModal from './RoutesModal';
import DownArrowIcon from './DownArrowIcon';
import Dex from './Dex';

export default function Result({
  currentDex,
  dexs,
  token,
  chain,
  onChangeDex,
}) {
  const [gasCostValues, setGasCostValues] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const getTotalCost = async () => {
    try {
      const _priceRes = await fetch(
        `https://api.coingecko.com/api/v3/simple/price?vs_currencies=usd&ids=${chain.nativeCurrency.coinGeckoId}`
      );
      const priceRes = await _priceRes.json();
      const price = priceRes[chain.nativeCurrency.coinGeckoId].usd;
      let _gasCostValues = {};
      dexs.forEach((dex) => {
        _gasCostValues[dex.name] = new BigNumber(price || 0)
          .multipliedBy(dex.gasCost)
          .toFixed(3);
      });
      setGasCostValues(_gasCostValues);
    } catch (err) {}
  };
  useEffect(() => {
    if (dexs.length) {
      getTotalCost();
    }
  }, [dexs]);
  return (
    <div className='result-wrapper'>
      <RoutesModal
        token={token}
        dexs={dexs}
        gasCostValues={gasCostValues}
        isOpen={isOpen}
        currentDex={currentDex}
        setIsOpen={setIsOpen}
        onChangeDex={onChangeDex}
      />
      <Flex justify='space-between'>
        <div className='result-title'>Select Bridge Route</div>
        <Flex
          className='result-links'
          gap={4}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <span>View all {dexs.length} Routes</span>
          <div className='result-link-header'>
            <DownArrowIcon />
          </div>
        </Flex>
      </Flex>
      <div className='result'>
        {currentDex && (
          <Dex
            dex={currentDex}
            token={token}
            gasCost={gasCostValues[currentDex.name]}
          />
        )}
      </div>
    </div>
  );
}
