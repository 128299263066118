import { Modal, Flex } from 'antd';

export default function ChainsModal({ list, isOpen, setIsOpen, modifyChain }) {
  return (
    <Modal
      open={isOpen}
      footer={null}
      onCancel={() => {
        setIsOpen(false);
      }}
      title='SELECT NETWORK'
      width={500}
    >
      <div className='modalContent'>
        <div className='chains'>
          {list.map((chain) => {
            return (
              <Flex
                className='chain-item'
                key={chain.id}
                onClick={() => modifyChain(chain)}
                gap='16px'
              >
                <img src={chain.icon} className='chain-logo' />
                <div className='chain-name'>{chain.name}</div>
              </Flex>
            );
          })}
        </div>
        {list.length === 0 && (
          <div style={{ textAlign: 'center', color: 'rgb(100 116 139)' }}>
            No Chains.
          </div>
        )}
      </div>
    </Modal>
  );
}
