import kava from './kava';
import arbitrum from './arbitrum';
import avalanche from './avalanche';
import base from './base';
import bsc from './bsc';
import fantom from './fantom';
import linea from './linea';
import metis from './metis';
import optimism from './optimism';
import polygon from './polygon';
import ethereum from './ethereum';

export default {
  ...kava,
  ...ethereum,
  ...polygon,
  ...optimism,
  ...metis,
  ...linea,
  ...fantom,
  ...bsc,
  ...base,
  ...avalanche,
  ...arbitrum,
};
